<template>
  <header
    id="public-header"
    class="tw-bg-white"
    :class="{ active: scrollPosition > 0 }"
    :style="{ padding: referrer.theme.headerPadding }"
  >
    <v-container class="tw-pt-0 tw-mt-0">
      <div class="tw-text-black-100 tw-flex tw-justify-between tw-items-center">
        <div>
          <img
            class="tw-cursor-pointer"
            :style="{
              width: referrer.theme.logoWidth,
              height: referrer.theme.logoHeight,
            }"
            :src="referrer.logo"
            alt="logo"
            @click="navigate('landing-public')"
          />
        </div>
        <ul class="flex-1 tw-hidden lg:tw-flex" v-if="show_default_header">
          <li class="public-header-list-li" v-if="show_expert">
            <button class="public-header-list-li-a" @click="loginToExpert">
              Switch to expert
            </button>
          </li>
          <li class="public-header-list-li">
            <router-link
              tag="a"
              class="public-header-list-li-a"
              :to="CheckIfHome + '#second'"
              >Vision</router-link
            >
          </li>
          <li class="public-header-list-li">
            <router-link
              class="public-header-list-li-a"
              tag="a"
              :to="CheckIfHome + '#ResultSection'"
              >Vos obligations</router-link
            >
          </li>
          <li class="public-header-list-li">
            <router-link
              tag="a"
              class="public-header-list-li-a"
              :to="CheckIfHome + '#ContactSection'"
              >Contact</router-link
            >
          </li>
          <li class="public-header-list-li">
            <router-link
              tag="a"
              class="public-header-list-li-a"
              :to="
                referrer.title === 'Viqtor Expert'
                  ? { name: 'ExpertLoginPage' }
                  : { name: 'LoginPage' }
              "
              >Se connecter</router-link
            >
          </li>
        </ul>
        <StepsComponent 
          v-if="show_stepper[$route.name]"
          :step="show_stepper[$route.name]"
          style="width: 80%;"
        />
        <img
          class="flex-1 tw-flex lg:tw-hidden"
          src="@/assets/images/icons/menu.svg"
          alt="menu"
          @click="showSidebar = true"
        />
        <SidebarComponent v-if="showSidebar"></SidebarComponent>
      </div>
    </v-container>
  </header>
</template>

<script>
import SidebarComponent from "@/features/auth/components/SidebarComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";

export default {
  name: "Header",
  components: {
    StepsComponent,
    SidebarComponent,
  },
  data() {
    return {
      showSidebar: false,
      scrollPosition: null,
    };
  },
  computed: {
    CheckIfHome: function () {
      return this.$router.resolve({ name: "landing-public" }).resolved.fullPath;
    },
    referrer() {
      return this.$store.state.referrer;
    },
    show_expert() {
      return this.$store.state.tokenExpert;
    },
    show_default_header() {
      return (
        this.$route.name !== "PreInscriptionLogin" &&
        this.$route.name !== "PreInscriptionFirstStep" &&
        this.$route.name !== "SetupAccountCompany" &&
        this.$route.name !== "SetupAccountPricingCompany" &&
        this.$route.name !== "SetupAccountExpert" &&
        this.$route.name !== "Auth.Registration.Expert.PreRegister" &&
        this.$route.name !== "Auth.Registration.Expert.PreRegisterConfirm" &&
        this.$route.name !== "Auth.Registration.Expert.SecondStep" &&
        this.$route.name !== "Auth.Registration.Expert.LastStep"
      );
    },
    show_stepper() {
      return {
        'Auth.Registration.Expert.PreRegister': 1,
        'Auth.Registration.Expert.PreRegisterConfirm': 1,
        'Auth.Registration.Expert.SecondStep': 2,
        'Auth.Registration.Expert.LastStep': 3,
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    navigate(nav) {
      this.$router.push({ name: nav });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    loginToExpert() {
      this.$store
        .dispatch("setToken", this.$store.state.tokenExpert)
        .then(() => {
          this.$store.dispatch("removeExpertToken").then(() => {
            this.$router.push({ name: "Company.Expert.DashboardPage" });
            this.$snackbar.showAlertMessage({
              message: "Vous êtes connecté en tant qu'expert",
              type: "success",
            });
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
header {
  z-index: 99999999999999;
  transition: all 0.15s linear;
}

header.active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
</style>
