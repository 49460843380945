<template>
  <v-container class="tw-pt-0 tw-mt-0 no-margin">
    <div
      class="auth-container tw-mt-10 tw-min-h-screen tw-relative tw-flex tw-flex-col"
    >
      <v-overlay :value="isLoadingData" class="tw-z-99">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <TitleComponent class="public-title-auth" :text="pageTitle" />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-8">
        Êtes-vous bien le bon interlocuteur pour répondre à cette évaluation ?
      </SignupSecondaryTextComponent>
      <v-form @submit="onSubmit" class="tw-pb-16 tw-flex-1" autocomplete="off">
        <div
          class="tw-flex tw-mx-6 tw-justify-center tw-content-center tw-flex-col tw-space-y-2 form-handler"
        >
          <svg
            width="45"
            height="36"
            viewBox="0 0 45 36"
            :fill="referer.theme.ctaBgColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 35L22.9388 1L44 35H1Z"
              :fill="referer.theme.ctaBgColor"
              :stroke="referer.theme.ctaBgColor"
            />
            <path
              d="M24.7578 12.96L24.3738 24.552H21.5418L21.1338 12.96H24.7578ZM23.0298 30.168C22.4218 30.168 21.9178 29.984 21.5178 29.616C21.1338 29.232 20.9418 28.76 20.9418 28.2C20.9418 27.64 21.1338 27.176 21.5178 26.808C21.9178 26.424 22.4218 26.232 23.0298 26.232C23.6218 26.232 24.1098 26.424 24.4938 26.808C24.8778 27.176 25.0698 27.64 25.0698 28.2C25.0698 28.76 24.8778 29.232 24.4938 29.616C24.1098 29.984 23.6218 30.168 23.0298 30.168Z"
              fill="white"
            />
          </svg>
          <p
            class="tw-text-center"
            :style="{
              color: `${referer.theme.ctaBgColor}`,
            }"
          >
            Pour répondre au questionnaire et préalablement renseigner les
            informations relatives à votre entreprise, vous allez être redirigé
            sur Viqtor®.
          </p>
          <br />
          <br />
          <div class="tw-w-full tw-flex tw-justify-between">
            <v-btn
              :class="answer == true ? 'primary-btn' : 'dark-btn'"
              :loading="isSendingForm"
              :disabled="isSendingForm"
              type="button"
              @click="handelClickButton(true)"
              elevation="1"
              text
              rounded
              large
              >Oui
            </v-btn>

            <v-btn
              :class="answer == false ? 'primary-btn' : 'dark-btn'"
              :loading="isSendingForm"
              :disabled="isSendingForm"
              type="button"
              @click="handelClickButton(false)"
              elevation="1"
              text
              rounded
              large
              >Non
            </v-btn>
          </div>
        </div>

        <div class="tw-flex tw-mx-6 tw-justify-end tw-mt-14">
          <PrimaryButtonComponent
            :loading="isSendingForm"
            :disabled="answer == null"
            type="submit"
            :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor}  !important`,
            }"
            >Valider
            <v-icon right dark> mdi-arrow-right </v-icon>
          </PrimaryButtonComponent>
        </div>
      </v-form>
      <mention-legal></mention-legal>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import MentionLegal from "@/components/common/MentionLegal";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import {
  getDataFromToken,
  AssistSendInvitationSubcontractorApi,
} from "../../services/appApi";

export default {
  components: {
    PrimaryButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
    MentionLegal,
  },
  data() {
    return {
      answer: null,
      isLoadingData: true,
      isSendingForm: false,
      company: null,
    };
  },
  computed: {
    pageTitle() {
      return (
        "Vous êtes le bon interlocuteur de " +
        (this.company ? this.company.tradeName : "") +
        " pour répondre au questionnaire d’évaluation de la conformité au RGPD de votre entreprise."
      );
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      if (this.answer) {
        this.handelSubmitAnswerYes();
      } else {
        this.handelSubmitAnswerYNo();
      }
    },

    fetchData() {
      getDataFromToken(this.$route.params.token)
        .then(({ data: { data } }) => {
          this.isLoadingData = false;
          this.company = data.workInCompany;
        })
        .catch(({ response }) => {
          this.isLoadingData = false;
          if (response.status === 404) {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
            this.$router.replace({ name: "TokenExpired" });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: quelque chose s'est mal passé",
              type: "error",
            });
          }
        });
    },

    handelClickButton(answer) {
      this.answer = answer;
    },

    handelSubmitAnswerYes() {
      AssistSendInvitationSubcontractorApi(
        this.$route.params.token,
        this.$route.params.id
      )
        .then((response) => {
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "success",
          });
          this.$router.push({
            name: "SubContractor.EmailSending",
          });
        })
        .catch(({ response }) => {
          this.isSendingForm = false;

          this.$notify({
            group: "foo",
            type: "error",
            title: "Attention",
            text: response.data.message,
          });

          if (response.status === 400) {
            this.errors = { ...this.errors, ...response.data };
          }
          this.$refs.form.validate();
        });
    },

    handelSubmitAnswerYNo() {
      this.$router.replace({
        name: "SubContractor.AddSubContractor.VerifiedStepTwo",
        params: {
          token: this.$route.params.token,
        },
      });
    },
  },
  mounted() {
    this.answer = this.$route.query.answer == "oui";
    this.fetchData();
  },
};
</script>

<style scoped>
form {
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.form-handler {
  width: 50%;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.no-margin {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
