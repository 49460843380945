<template>
  <v-container class="tw-pt-0 tw-mt-0" id="pre-register-expert-page">
    <div class="auth-container tw-mt-10 tw-min-h-screen tw-relative">
      <TitleComponent 
        class="tw-mt-8" 
        text="Création de votre compte multi-entités/clients"
      />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
        Une fois votre compte créé, vous serrez en mesure d’inscrire et de gérer les comptes des entités et des clients dont vous piloterez la conformité RGPD.
      </SignupSecondaryTextComponent>

      <v-form
        ref="form"
        @submit="onSubmit"
        v-model="formValid"
        class="tw-mt-8"
        autocomplete="off"
      >
        <div>
          <span class="tw-font-semibold tw-text-2xl">Vous</span>

          <div class="tw-mt-6 tw-flex tw-items-center tw-space-x-6">
            <SelectBoxComponent
              class="custom-select-box"
              style="width: 14rem;"
              item-labels-classes="tw-text-sm"
              label="Civilité"
              v-model="form.gender"
              :items="genderItems"
              :rules="rules.gender"
              :error-messages="errors.gender"
              no-single-line
            />
            <TextFieldComponent
              style="width: 20rem;"
              :rules="rules.firstName"
              :error-messages="errors.firstName"
              v-model="form.firstName"
              label="Prénom"
            />
            <TextFieldComponent
              style="width: 20rem;"
              :rules="rules.lastName"
              v-model="form.lastName"
              :error-messages="errors.lastName"
              label="Nom"
            />
          </div>
          <div class="tw-mt-4 tw-flex tw-items-center tw-space-x-6">
            <TextFieldComponent
              style="width: 41.5rem;"
              :rules="rules.email"
              v-model="form.email"
              :error-messages="errors.email"
              label="Email"
            />
            <TextFieldComponent
              style="width: 14rem;"
              :rules="rules.phone"
              v-model="form.phone"
              :error-messages="errors.phone"
              label="Téléphone"
            />
          </div>
        </div>

        <div class="tw-mt-4" v-if="companiesStructures.length > 0">
          <span class="tw-font-semibold tw-text-2xl">Votre entreprise</span>

          <div class="tw-flex tw-mt-6 tw-space-x-6">
            <div>
              <div class="tw-flex tw-items-center tw-space-x-6">
                <TextFieldComponent
                  style="width: 20rem;"
                  :rules="rules.socialReason"
                  :error-messages="errors.socialReason"
                  v-model="form.socialReason"
                  label="Raison sociale"
                />
                <TextFieldComponent
                  style="width: 11rem;"
                  :rules="rules.sirenOrRna"
                  v-model="form.sirenOrRna"
                  :error-messages="errors.sirenOrRna"
                  label="N° Siren / Rna"
                />
              </div>
              <div class="tw-mt-4">
                <span class="tw-font-semibold tw-text-xs tw-text-theme-primary">Sélectionnez le type de structure</span>
                <div 
                  v-for="(companyStructure, index) in companiesStructures"
                  :key="index"
                  class="tw-flex tw-flex-col tw-border tw-border-theme-primary hover:tw-bg-theme-primary hover:tw-text-white tw-rounded-lg tw-mt-2 tw-pl-4 tw-py-2 tw-cursor-pointer"
                  :class="{
                    'tw-border-theme-primary': selectedCompanyStructureId !== companyStructure.id,
                    'tw-bg-theme-primary tw-text-white': selectedCompanyStructureId === companyStructure.id
                  }"
                  style="width: 520px;"
                  @click="(selectedCompanyStructureId = companyStructure.id, selectedCompanyStructureTypeId = companiesStructures[companyStructure.id - 1].structureTypes[0].id)"
                >
                  <span class="tw-font-medium">{{ companyStructure.title }}</span>
                  <span class="tw-text-xs">{{ companyStructure.description }}</span>
                </div>
              </div>
            </div>

            <div class="tw-mt-2">
              <span class="tw-font-semibold tw-text-xs tw-text-theme-primary">Type de stucture</span>
              <div class="tw-flex tw-flex-col tw-border tw-border-theme-primary tw-rounded-lg tw-mt-2 tw-pl-6 tw-pr-8 tw-py-6 tw-space-y-2">
                <span 
                  class="tw-text-xs tw-font-medium hover:tw-text-theme-primary tw-cursor-pointer"
                  :class="{ 'tw-text-theme-primary': selectedCompanyStructureTypeId === structureType.id }"
                  v-for="(structureType, index) in companiesStructures[selectedCompanyStructureId - 1].structureTypes"
                  :key="index"
                  @click="selectedCompanyStructureTypeId = structureType.id"
                >{{ structureType.title }}</span>
              </div>
            </div>

            <div class="tw-mt-14" style="width: 120px; line-height: 14px;">
              <span class="tw-text-xs tw-text-theme-primary">
                {{ companiesStructures[selectedCompanyStructureId - 1].structureTypes.find(item => item.id === selectedCompanyStructureTypeId).description }}
              </span>
            </div>
          </div>
        </div>
          
        <div class="tw-flex tw-justify-center tw-pb-20 tw-mt-11">
          <PrimaryButtonComponent :loading="isSendingForm" type="submit">Valider</PrimaryButtonComponent>
        </div>
      </v-form>
      
      <p class="tw-absolute tw-bottom-0 registration-iml">
        Les informations à caractère personnel recueillies dans le cadre
        contractuel de votre souscription à la plateforme Viqtor sont enregistrées
        dans un fichier informatisé par la société DBWO pour vous permettre
        l’accès aux services qu’elle propose, assurer l’interaction de votre
        organisation avec ses partenaires ainsi que vous tenir informés de
        l’évolutions de ses prestations. Elles seront conservées tout au long de
        la durée de souscription au service, période durant laquelle elles
        pourront être régulièrement mises à jour à votre demande. Hors demande de
        votre part, elles seront détruites après résiliation de la souscription
        par votre organisation sous un délai de 3 mois après le terme du contrat.
        Ces données sont destinées au personnel administratif de DBWO et
        conservées sur les serveurs de l’entreprise OVH. Conformément à la loi «
        informatique et libertés », vous pouvez exercer votre droit d'accès aux
        données vous concernant et les faire rectifier en contactant :
        dpo@dbwo.legal
      </p>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import SelectBoxComponent from "@/components/common/SelectBoxComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { validationRules } from "@/utils/validation-rules";
import { postPreRegistrationExpert } from "@/features/auth/services/appApi";
import { getAllCompanyStructures } from "@/features/company/services/appApi";

export default {
  name: "PreRegisterExpertPage",
  components: {
    TitleComponent,
    SignupSecondaryTextComponent,
    SelectBoxComponent,
    PrimaryButtonComponent
  },
  data() {
    return {
      formValid: false,
      isSendingForm: false,
      companiesStructures: [],
      selectedCompanyStructureId: 1,
      selectedCompanyStructureTypeId: 1,
      genderItems: [
        { label: "Madame", value: "female" },
        { label: "Monsieur", value: "male" },
        { label: "Non précisé", value: "other" },
      ],
      form: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        phone: "",
        socialReason: "",
        sirenOrRna: "",
        structureTypeId: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        phone: "",
        socialReason: "",
        sirenOrRna: "",
        structureTypeId: "",
      },
      rules: {
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "lastName"),
        ],
        gender: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "gender"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
        socialReason: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "socialReason"),
        ],
        sirenOrRna: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "sirenOrRna"),
        ],
      }
    }
  },
  mounted() {
    this.getCompanyStructures();
  },
  watch: {
    selectedCompanyStructureTypeId(value) {
      this.form.structureTypeId = value;
    }
  },
  methods: {
    getCompanyStructures() {
      getAllCompanyStructures()
        .then(({ data }) => {
          let id = 1;
          Map.groupBy(data, (item => item.structureTypeTitle)).forEach((value, key) => {
            let groupedStructure = {};

            if (!Object.keys(this.companiesStructures).includes(key))
              groupedStructure = {
                id: id++,
                title: key,
                description: value[0].structureTypeDescription,
                structureTypes: []
              };

            value.forEach((item) => {
              groupedStructure.structureTypes.push({
                id: item.id,
                title: item.title,
                description: item.description,
              });
            });

            this.companiesStructures.push(groupedStructure);
          })

          this.form.structureTypeId = this.companiesStructures[0].structureTypes[0].id;
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Erreur lors de la récupération des structures des entreprises",
            type: "error",
          });
        })
    },
    onSubmit($event) {
      $event.preventDefault();
      if (!this.companiesStructures.length) {
        this.$snackbar.showAlertMessage({
          message: "Vous ne pouvez pas créer de compte pour le moment, veuillez réessayer plus tard",
          type: "error",
        });
        return;
      }
      if (!this.formValid) {
        this.$snackbar.showAlertMessage({
          message: "Veuillez remplir correctement tous les champs",
          type: "error",
        });
        return;
      }

      this.isSendingForm = true;
      postPreRegistrationExpert(this.form)
        .then(({ data }) => {
          this.$snackbar.showAlertMessage({
            message: "Votre compte a été bien créé.",
            type: "success",
          });
          const token = data.data.finishInscriptionToken;
          this.$router.push({ name: 'Auth.Registration.Expert.PreRegisterConfirm', params: { token } });
          window.scrollTo(0, 0);
        })
        .catch(({ response }) => {
          window.scrollTo(0, 0);
          
          if (response.status === 400) {
            Object.keys(response.data.errors).forEach((key) => {
              this.errors[key] = response.data.errors[key] 
                ? response.data.errors[key][0] : "";
            });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: la requete a échoué",
              type: "error",
            });
          }
          
          this.$refs.form.validate();
        })
        .finally(() => {
          this.isSendingForm = false;
        })
    },
  },
};
</script>
<style>
#pre-register-expert-page .v-input {
  font-size: 14px !important;
}
</style>