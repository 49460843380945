import Vue from 'vue';
import VueRouter from "vue-router";
import authRoutes from './landing';
import companyRoutes from './company';
import questionnaireRoutes from './questionnaire'
import elearningRoutes from './elearning'
import { serverConfig } from '@/config/serverConfig';
import store from '../store/index.js';
import { checkIfUserCanBypassMaintenance, getProfile } from "@/features/auth/services/appApi";
import axios from 'axios';

Vue.use(VueRouter)

const FORBIDDEN_MODULES_FOR_FREE_PLAN = ['Collaborators', 'Governance', 'Register', 'Elearning', 'Ecosystem'];
const AUTHANTIFICATION_ROUTES = ['LoginPage', 'signup', 'ExpertLoginPage'];
const PAYMENT_ROUTES = ['pending', 'SetupAccountExpert', 'SetupAccountCompany', 'SetupAccountPricingCompany'];

const router = new VueRouter({
	mode: 'history',
	base: serverConfig.base_name,
	routes: [ 
		...authRoutes, 
		...companyRoutes, 
		...questionnaireRoutes, 
		...elearningRoutes,
		{
			name: "Fallback.ErrorPage",
			path: "*",
			props: true,
			redirect: { name: "ErrorPage" },
		},
	],
	linkExactActiveClass: 'active',
	scrollBehavior: (to) => to.hash ? { selector: to.hash } : { x: 0, y: 0 },
});

router.afterEach(() => setTimeout(() => { store.dispatch('loader') }, 500));

router.beforeEach(async (to, from, next) => {
	await maintenanceModeMiddleware(to, next);
	await authMiddleware(to, next);

	if (
		to.matched.some(el => el.meta.elearning===true) &&
		!store.getters.getElearningToken
	) return next({ name: 'Collaborateurs' });

	if (
		to.matched.some(el => el.meta.questionnaire===true) &&
		!store.getters.getQuestionnaireToken
	) return next({ name: 'Collaborateurs' });

	return next();
});

async function maintenanceModeMiddleware(to, next) {
	const IS_EXPERT_PLATFORM = store.state.referrer.title === 'Viqtor Expert';
	const HAS_MAINTENANCE = store.state.referrer.hasMaintenance;
	const CAN_BYPASS_MAINTENANCE = store.state.canBypassMaintenance;

	if (!HAS_MAINTENANCE && to.name === 'MaintenancePage')
		return next({ name: IS_EXPERT_PLATFORM ? 'ExpertLoginPage' : 'LoginPage' });

	if (HAS_MAINTENANCE && !CAN_BYPASS_MAINTENANCE && to.name !== 'MaintenancePage')
		return next({ name: 'MaintenancePage' });

	if (HAS_MAINTENANCE && !CAN_BYPASS_MAINTENANCE && to.name === 'MaintenancePage') {
		if (!to.query.key) return next();
		try {
			const response = await checkIfUserCanBypassMaintenance(to.query.key);
			if (!response.data) return next();

			axios.defaults.headers.common['x-maintenance-bypass-key'] = to.query.key;
			store.commit('SET_CAN_BYPASS_MAINTENANCE', true);
			store.commit('SET_BYPASS_MAINTENANCE_KEY', to.query.key);

			return next({ name: IS_EXPERT_PLATFORM ? 'ExpertLoginPage' : 'LoginPage' });
		} 
		catch(error) { return next(); }
	}
}

async function authMiddleware(to, next) {
	const IS_EXPERT_PLATFORM = store.state.referrer.title === 'Viqtor Expert';
	const HAS_MAINTENANCE = store.state.referrer.hasMaintenance;
	const CAN_BYPASS_MAINTENANCE = store.state.canBypassMaintenance;

	if (store.state.user && AUTHANTIFICATION_ROUTES.includes(to.name))
		return next({ name: 
			IS_EXPERT_PLATFORM && store.state.user.expertType !== 'company'
				? 'Company.Expert.DashboardPage' : 'Company.DashboardPage' 
		});

	if (to.matched.some(el => el.meta.requiresAuth)) {
		const isRoutingToForbiddenModule = FORBIDDEN_MODULES_FOR_FREE_PLAN
			.some(m => to.path.includes(m.toLowerCase()));

		try {
			const response = await getProfile();
	
			const user = response.data.data;
			const company = response.data.data.workInCompany;
			const isComapanyHasActiveSubscription = checkIfCompanyHasActiveSubscription(user, company);
			store.commit('set_auth', { user });
	
			if (company.referral !== store.state.referrer.domain)
				throw new Error('Company is not registered in this platform');

			if (HAS_MAINTENANCE && !CAN_BYPASS_MAINTENANCE && !user.canBypassMaintenance)
				return next({ name: 'MaintenancePage' });
	
			if (!isComapanyHasActiveSubscription && !PAYMENT_ROUTES.includes(to.name))
				return next({ name: 'pending' });
	
			if (isComapanyHasActiveSubscription && PAYMENT_ROUTES.includes(to.name))
				return next({ name: 
					IS_EXPERT_PLATFORM && user.expertType !== 'company'
						? 'Company.Expert.DashboardPage' : 'Company.DashboardPage' 
				});

			if (IS_EXPERT_PLATFORM && user.expertType === 'client' && isRoutingToForbiddenModule)
				return next({ name: 'Company.Expert.DashboardPage' });	
			
			if (!IS_EXPERT_PLATFORM && !!company.planPrice && company.planPrice.price === 0 && isRoutingToForbiddenModule)
				return next({ name: 'Company.DashboardPage' });

			return next();
		}
		catch (error) {
			console.log(error);
			store.commit('logout');
			return next({ name: IS_EXPERT_PLATFORM ? 'ExpertLoginPage' : 'LoginPage' });
		}
	}
}

function checkIfCompanyHasActiveSubscription(user, company){
	const IS_EXPERT_PLATFORM = store.state.referrer.title === 'Viqtor Expert';
	const ts = Math.round(new Date().getTime() / 1000);
	const isSomeCompanySubscriptionActive = company.subscriptions
		.some(s => s.startDate <= ts && s.endDate >= ts && s.status == 'succeeded');
	const userExpertType = user.expertType;
	const isUserCollaborator = user.isCollaborator;

	if (IS_EXPERT_PLATFORM && userExpertType === 'company')
		return true;

	if (IS_EXPERT_PLATFORM && userExpertType === 'client' && isUserCollaborator)
		return true;

	if (IS_EXPERT_PLATFORM && userExpertType === 'client' && company.isExpertAccountActive)
		return true;

	if (!IS_EXPERT_PLATFORM && !!company.planPriceId && isSomeCompanySubscriptionActive)
		return true;

	return false;
}

export default router;