<template>
  <v-btn
      elevation="1"
      text
      v-bind="$attrs"
      rounded
      large
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "SubmitButtonComponent",
  props: ["onClick"],
};
</script>

<style scoped></style>
