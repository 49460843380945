<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-relative">
      <TitleComponent class="public-title-auth" text="Sélectionnez votre formule et votre mode de paiement !" />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-6">
        <b>
          IMPORTANT : pour accéder et profiter gratuitement du module d’évaluation de
          la sous-traitance, vous devez enregistrer votre titre de paiement.
        </b>
      </SignupSecondaryTextComponent>
      <SignupSecondaryTextComponent class="public-text-register tw-mt-6 dynamic-text">
        <b> Récapitulatif de votre offre : </b>
      </SignupSecondaryTextComponent>
      <SignupSecondaryTextComponent class="public-text-register" v-if="!!plan.plan">
        {{ plan.plan.salaryRange }} salariés - Jusqu’à {{ plan.plan.subContractorNumber }} sous-traitants - abonnements
        /
        {{ plan.type === 'monthly' ? 'mensuel' : 'annuel' }} : {{ plan.price }}€ HT
        avec un engagement : {{ plan.engagement === 24 ? '2 ans' : '1 an' }}
      </SignupSecondaryTextComponent>
      <div class="tw-flex tw-flex-col tw-mt-6">
        <SignupSecondaryTextComponent class="public-text-register">
          <b>
            Votre abonnement à la plateforme est sans engagement, il peut être interrompu de votre <br />
            part à tout moment. La dernière période contractée et payée est due jusqu’à son terme.
          </b>
        </SignupSecondaryTextComponent>
      </div>
      <div class="tw-flex tw-flex-col">
        <SignupSecondaryTextComponent class="public-text-register bleu tw-mt-6 dynamic-text">
          <b> Sélectionnez un mode de paiement </b>
        </SignupSecondaryTextComponent>
        <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center">
          <PaiementModeItem v-for="item in paiementTypes" :key="item.id" @click.native="choseOneMode(item.value)"
            :title="item.title" :img="item.img" :item="item.value" :selected-item="selectedPaiement" />
        </div>
      </div>
      <div class="md:tw-w-9/12 md:tw-mt-6">
        <VisaPaiementForm :user="plan" :selected-type="selectedType" :clientSecret="clientSecret" :publicKey="publicKey" v-if="publicKey && clientSecret && selectedPaiement === 'card'" />
        <SepaPaiementForm :user="plan" :selected-type="selectedType" :clientSecret="clientSecret" :publicKey="publicKey" v-if="publicKey && clientSecret && selectedPaiement === 'sepa_debit'" />
      </div>
      <div class="tw-flex tw-justify-between tw-pb-20 tw-mt-8">
        <PrimaryButtonComponent class="back-btn" @click="goBack">
          <svg class="tw-mr-3" width="16" height="12" viewBox="0 0 16 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M15 6L1 6" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M6 11L1 6L6 1" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>

          Précédent
        </PrimaryButtonComponent>
      </div>
    </div>
  </v-container>
</template>

<script>

import { getAllOffers, getProfile, createClientSecret, createClientSecretSepa } from "@/features/auth/services/appApi";
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PaiementModeItem from "@/features/auth/components/PaiementModeItem";
import VisaPaiementForm from "@/features/auth/components/VisaPaiementForm";
import SepaPaiementForm from "@/features/auth/components/SepaPaiementForm";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "SetupAccountPricingCompanyPage",
  components: {
    PrimaryButtonComponent,
    VisaPaiementForm,
    SepaPaiementForm,
    PaiementModeItem,
    SignupSecondaryTextComponent,
    TitleComponent
  },
  data() {
    return {
      clientSecret: null,
      publicKey: null,
      user: {},
      formValid: false,
      isSendingForm: false,
      selectedPaiement: '',
      selectedType: '',
      plan: {},
      subscribeTypes: [
        {
          value: 'month',
          label: 'Abonnement mensuel avec prélèvement tous les mois'
        },
        {
          value: 'year',
          label: 'Abonnement annuel avec prélèvement chaque année'
        }
      ],
      paiementTypes: [
        {
          id: 1,
          title: 'Cartes de crédit et de débit',
          img: 'visa.svg',
          value: 'card'
        },
        {
          id: 2,
          title: 'SEPA Stripe',
          img: 'sepa_debit.png',
          value: 'sepa_debit'
        },
        // {
        //   id: 2,
        //   title: 'Paylib',
        //   img: 'pay.svg',
        //   value: 'paylib'
        // },
        // {
        //   id: 3,
        //   title: 'PayPal entreprise',
        //   img: 'paypal.svg',
        //   value: 'paypal'
        // },
        // {
        //   id: 4,
        //   title: 'Compte bancaire',
        //   img: 'sepa.svg',
        //   value: 'sepa'
        // },
      ]
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  mounted() {
    this.getUserData();
    this.getAllOffers();
  },
  methods: {
    createClientSecretSetup(paymentMethodType="sepa_debit"){
      if (paymentMethodType === 'sepa_debit') {
        createClientSecretSepa({ paymentMethodType, planPriceId: this.plan.id })
        .then(response => {
          this.clientSecret = response.data.data.clientSecret;
          this.publicKey = response.data.data.publicKey;
        })
        .catch(() => '');
      }
      else {
        createClientSecret({ paymentMethodType, planPriceId: this.plan.id })
        .then(response => {
          this.clientSecret = response.data.data.clientSecret;
          this.publicKey = response.data.data.publicKey;
        })
        .catch(() => '');
      }
    },
    getAllOffers() {
      getAllOffers()
        .then(response => {
          response.data.data.forEach(item => {
            if (item.id === Number(this.$route.params.planId)) {
              this.plan = item
            }
          });
        })
        .catch(() => '');
    },
    async choseOneMode(name) {
      await this.createClientSecretSetup(name);
      this.formValid = true;
      this.selectedPaiement = name;
      this.formValid = false;
    },
    goBack() {
      this.$router.push({ name: 'SetupAccountCompany' });
    },
    getUserData() {
      getProfile()
        .then(response => {
          if (response.data.data.plan) {
            this.user = response.data.data;
          }

        })
        .catch(() => '');
    },
    onSubscribeTypeChange(value) {
      this.selectedType = value;
    }
  },
};
</script>

<style scoped>

</style>
