<template>
  <div>
    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="profile-informations-form">
        <EntrepriseHeaderBlockTextComponent
          title="Mes informations"
          description=""
        />

        <a class="tw-mt-11 tw-flex tw-items-center tw-space-x-2" @click.prevent="$router.push({ name: 'Company.Profile' })">
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.87575 4.87492H2.8945L5.9445 1.82492C6.18825 1.58117 6.18825 1.18117 5.9445 0.937416C5.88668 0.879476 5.818 0.833509 5.74239 0.802146C5.66678 0.770782 5.58573 0.754639 5.50387 0.754639C5.42202 0.754639 5.34096 0.770782 5.26536 0.802146C5.18975 0.833509 5.12107 0.879476 5.06325 0.937416L0.944496 5.05617C0.886557 5.11399 0.840589 5.18267 0.809226 5.25828C0.777863 5.33388 0.761719 5.41494 0.761719 5.49679C0.761719 5.57865 0.777863 5.6597 0.809226 5.73531C0.840589 5.81091 0.886557 5.8796 0.944496 5.93742L5.06325 10.0562C5.12111 10.114 5.1898 10.1599 5.26541 10.1912C5.34101 10.2226 5.42204 10.2387 5.50387 10.2387C5.5857 10.2387 5.66673 10.2226 5.74234 10.1912C5.81794 10.1599 5.88663 10.114 5.9445 10.0562C6.00236 9.9983 6.04826 9.92961 6.07958 9.85401C6.11089 9.7784 6.12701 9.69737 6.12701 9.61554C6.12701 9.53371 6.11089 9.45268 6.07958 9.37708C6.04826 9.30147 6.00236 9.23278 5.9445 9.17492L2.8945 6.12492H9.87575C10.2195 6.12492 10.5007 5.84367 10.5007 5.49992C10.5007 5.15617 10.2195 4.87492 9.87575 4.87492Z" fill="#25282B"/>
          </svg>
          <span class="tw-border-b tw-border-black tw-leading-none">Retour</span>
        </a>

        <div class="tw-mt-10 tw-py-2">
          <h2 class="tw-font-semibold tw-text-lg tw-text-[#25282B]">Informations personnelles</h2>
          <v-form @submit.prevent="onPersonalInfoFormSubmit" class="tw-mt-0">
            <RadioButtonComponent
              v-model="personalInfoForm.gender"
              :items="genderItems"
              :disabled="disableInput"
              @change="onGenderChange"
            />
            <div class="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-2">
              <TextFieldComponent
                v-model="personalInfoForm.firstName"
                label="Prénom"
                :disabled="disableInput"
                :hide-details="personalInfoFormErrors.firstName.length === 0"
                :error="personalInfoFormErrors.firstName.length > 0"
                :error-messages="personalInfoFormErrors.firstName"
              />
              <TextFieldComponent
                v-model="personalInfoForm.lastName"
                label="Nom"
                :disabled="disableInput"
                :hide-details="personalInfoFormErrors.lastName.length === 0"
                :error="personalInfoFormErrors.lastName.length > 0"
                :error-messages="personalInfoFormErrors.lastName"
              />
              <TextFieldComponent
                v-model="personalInfoForm.phone"
                label="Téléphone mobile"
                :disabled="disableInput"
                :hide-details="personalInfoFormErrors.phone.length === 0"
                :error="personalInfoFormErrors.phone.length > 0"
                :error-messages="personalInfoFormErrors.phone"
              />
              <TextFieldComponent
                v-model="personalInfoForm.role"
                label="Poste"
                :disabled="disableInput"
                :hide-details="personalInfoFormErrors.role.length === 0"
                :error="personalInfoFormErrors.role.length > 0"
                :error-messages="personalInfoFormErrors.role"
              />
              <TextFieldComponent
                v-model="personalInfoForm.service"
                label="Service"
                :disabled="disableInput"
                :hide-details="personalInfoFormErrors.service.length === 0"
                :error="personalInfoFormErrors.service.length > 0"
                :error-messages="personalInfoFormErrors.service"
              />
              <div class="tw-flex tw-items-center tw-justify-end">
                <SubmitButtonComponent
                  class="tw-text-white tw-justify-self-end"
                  :style="{ backgroundColor: `${referrer.theme.ctaBgColor}` }"
                  :loading="isSendingPersonalInfoForm"
                  type="Modifier"
                >
                  <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">
                    {{ disableInput ? 'Modifier' : 'Valider' }}
                  </span>
                </SubmitButtonComponent>
              </div>
            </div>
          </v-form>
        </div>
        
        <div class="tw-mt-10 tw-py-2">
          <EntrepriseHeaderBlockTextComponent
            title="Sécurité"
            description="Adresse e-mail et mot de passe."
          />

          <h2 class="tw-mt-6 tw-font-semibold tw-text-sm tw-text-[#25282B]">Modifier votre adresse email</h2>
          <span class="tw-text-sm">Votre adresse e-mail actuelle est {{ currentEmail }}</span>
          <v-form @submit.prevent="onEmailAddressFormSubmit" class="tw-mt-2">
            <div class="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-2">
              <TextFieldComponent
                v-model="emailAddressForm.email"
                label="Nouvelle adresse e-mail"
                :hide-details="emailAddressFormErrors.email.length === 0"
                :error="emailAddressFormErrors.email.length > 0"
                :error-messages="emailAddressFormErrors.email"
              />
              <PasswordFieldComponent
                label="Mot de passe"
                class="passwordd"
                v-model="emailAddressForm.currentPassword"
                :hide-details="emailAddressFormErrors.currentPassword.length === 0"
                :error="emailAddressFormErrors.currentPassword.length > 0"
                :error-messages="emailAddressFormErrors.currentPassword"
              />
              <div></div>
              <div class="tw-flex tw-items-center tw-justify-end">
                <SubmitButtonComponent
                  class="tw-text-white tw-justify-self-end"
                  :style="{ backgroundColor: `${referrer.theme.ctaBgColor}` }"
                  :loading="isSendingEmailAddressForm"
                  type="Modifier"
                >
                  <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">Valider</span>
                </SubmitButtonComponent>
              </div>
            </div>
          </v-form>

          <v-divider class="tw-mt-10" style="background-color:#E4E4E4; width:100%;"></v-divider>

          <h2 class="tw-mt-6 tw-font-semibold tw-text-sm tw-text-[#25282B]">Modifier votre mot de passe</h2>
          <span class="tw-text-sm">Vous receverez un email pour confirmer la modification du mot de passe.</span>
          <v-form @submit.prevent="onResetPasswordFormSubmit" class="tw-mt-2">
            <div class="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-2">
              <PasswordFieldComponent
                v-model="resetPasswordForm.currentPassword"
                label="Mot de passe"
                class="passwordd"
                :hide-details="resetPasswordFormErrors.currentPassword.length === 0"
                :error="resetPasswordFormErrors.currentPassword.length > 0"
                :error-messages="resetPasswordFormErrors.currentPassword"
              />
              <PasswordFieldComponent
                v-model="resetPasswordForm.newPassword" 
                label="Nouveau mot de passe"
                class="passwordd"
                validate
                passwordRules="Au moins 12 caractères, dont 1 majuscule, 1 chiffre, 1 minuscule, 1 caractère
                  spécial (., @, !, _, %, #, :, $, *, ..., -)"
                :hide-details="resetPasswordFormErrors.newPassword.length === 0"
                :error="resetPasswordFormErrors.newPassword.length > 0"
                :error-messages="resetPasswordFormErrors.newPassword"
              />
              <div></div>
              <div class="tw-flex tw-items-center tw-justify-end">
                <SubmitButtonComponent
                  class="tw-text-white tw-justify-self-end"
                  :style="{ backgroundColor: `${referrer.theme.ctaBgColor}` }"
                  :loading="isSendingResetPasswordForm"
                  type="Modifier"
                >
                  <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">Valider</span>
                </SubmitButtonComponent>
              </div>
            </div>
          </v-form>
          
          <v-divider class="tw-mt-10" style="background-color:#E4E4E4; width:100%;"></v-divider>

          <h2 class="tw-mt-6 tw-font-semibold tw-text-sm tw-text-[#25282B]">Supprimer mon compte</h2>
          <span class="tw-text-sm">Attention, cette action est irréversible !</span>
          <v-form @submit.prevent="onDeleteAccountFormSubmit" class="tw-mt-2">
            <div class="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-2">
              <PasswordFieldComponent
                v-model="deleteAccountForm.currentPassword"
                label="Mot de passe"
                class="passwordd"
                :hide-details="deleteAccountFormErrors.currentPassword.length === 0"
                :error="deleteAccountFormErrors.currentPassword.length > 0"
                :error-messages="deleteAccountFormErrors.currentPassword"
              />
              <div></div>
              <div class="tw-flex tw-items-center">
                <SubmitButtonComponent
                  class="tw-text-white tw-justify-self-end"
                  :style="{ backgroundColor: `${referrer.theme.ctaBgColor}` }"
                  :loading="isSendingDeleteAccountForm"
                  type="Modifier"
                >
                  <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">Valider</span>
                </SubmitButtonComponent>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import RadioButtonComponent from "@/components/common/RadioButtonParamsComponent";
import TextFieldComponent from "@/components/common/TextFieldCompanyComponent";
import SubmitButtonComponent from "@/components/common/SubmitButtonComponent";
import PasswordFieldComponent from "@/components/common/PasswordFieldComponent";
import { updateUserApi, updateUserEmail, updateUserPassword, deleteAccountUser } from '../../../services/appApi';
import { mapState } from "vuex";

export default {
  name: "ProfileInformationsPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    RadioButtonComponent,
    TextFieldComponent,
    SubmitButtonComponent,
    PasswordFieldComponent
  },
  data() {
    return {
      loading: true,

      genderItems: [
        { label: "Madame", value: "female" },
        { label: "Monsieur", value: "male" },
      ],

      personalInfoForm: {
        gender: "",
        firstName: "",
        lastName: "",
        phone: "",
        role: "",
        service: "",
      },
      emailAddressForm: {
        email: "",
        currentPassword: "",
      },
      resetPasswordForm: {
        currentPassword: "",
        newPassword: "",
      },
      deleteAccountForm: {
        currentPassword: "",
      },

      personalInfoFormErrors: {
        firstName: "",
        lastName: "",
        phone: "",
        role: "",
        service: "",
      },
      emailAddressFormErrors: {
        email: "",
        currentPassword: "",
      },
      resetPasswordFormErrors: {
        currentPassword: "",
        newPassword: "",
      },
      deleteAccountFormErrors: {
        currentPassword: "",
      },

      currentEmail: "",
      disableInput: true,
      isSendingPersonalInfoForm: false,
      isSendingEmailAddressForm: false,
      isSendingResetPasswordForm: false,
      isSendingDeleteAccountForm: false,
    };
  },
  computed: {
    ...mapState(["referrer", "user"]),
  },
  mounted() {
    this.personalInfoForm.gender = this.user.gender;
    this.personalInfoForm.firstName = this.user.firstName;
    this.personalInfoForm.lastName = this.user.lastName;
    this.personalInfoForm.phone = this.user.mobilePhone;
    this.personalInfoForm.role = this.user.role;
    this.personalInfoForm.service = this.user.service;
    this.currentEmail = this.user.email;

    this.loading = false;
  },
  watch: {
    personalInfoForm: {
      handler() {
        Object.keys(this.personalInfoFormErrors).forEach((key) => {
          this.personalInfoFormErrors[key] = "";
        });
      },
      deep: true,
    },
    emailAddressForm: {
      handler() {
        Object.keys(this.emailAddressFormErrors).forEach((key) => {
          this.emailAddressFormErrors[key] = "";
        });
      },
      deep: true,
    },
    resetPasswordForm: {
      handler() {
        Object.keys(this.resetPasswordFormErrors).forEach((key) => {
          this.resetPasswordFormErrors[key] = "";
        });
      },
      deep: true,
    },
    deleteAccountForm: {
      handler() {
        Object.keys(this.deleteAccountFormErrors).forEach((key) => {
          this.deleteAccountFormErrors[key] = "";
        });
      },
      deep: true,
    },
  },
  methods: {
    onPersonalInfoFormSubmit() {
      if (this.disableInput) {
        this.disableInput = !this.disableInput;
        return;
      }
      
      if (!this.disableInput) {
        this.isSendingPersonalInfoForm = true;

        updateUserApi(this.personalInfoForm)
          .then(({ data }) => {
            this.$store.commit('set_auth', { user: data.data });
            this.$snackbar.showAlertMessage({ message: data.message, type: "success" });
            this.disableInput = true;
          })
          .catch(({ response }) => { 
            this.personalInfoFormErrors = { ...this.personalInfoFormErrors, ...response.data.errors }; 
          })
          .finally(() => { this.isSendingPersonalInfoForm = false; });
      }
    },
    onEmailAddressFormSubmit() {
      this.isSendingEmailAddressForm = true;

      updateUserEmail(this.emailAddressForm)
        .then(({ data }) => {
          this.$snackbar.showAlertMessage({ message: data.message, type: "success" });
          this.currentEmail = this.emailAddressForm.email;
          this.emailAddressForm.email = '';
          this.emailAddressForm.currentPassword = '';
        })
        .catch(({ response }) => {
          this.emailAddressFormErrors = { ...this.emailAddressFormErrors, ...response.data.errors };
        })
        .finally(() => { this.isSendingEmailAddressForm = false; });
    },
    onResetPasswordFormSubmit() {
      this.isSendingResetPasswordForm = true;

      updateUserPassword(this.resetPasswordForm)
        .then(({ data }) => {
          this.$snackbar.showAlertMessage({ message: data.message, type: "success" });
          this.resetPasswordForm.currentPassword = '';
          this.resetPasswordForm.newPassword = '';
        })
        .catch(({ response }) => {
          this.resetPasswordFormErrors = { ...this.resetPasswordFormErrors, ...response.data.errors };
        })
        .finally(()=>{ this.isSendingResetPasswordForm = false; });
    },
    onDeleteAccountFormSubmit() {
      this.isSendingDeleteAccountForm = true;

      deleteAccountUser(this.deleteAccountForm)
        .then(({ data }) => {
          this.$snackbar.showAlertMessage({ message: data.message, type: "success" });
          this.deleteAccountForm.currentPassword = '';
          this.$router.push({ name: 'LoginPage' })
        })
        .catch(({ response }) => {
          if (!response.data.errors)
            this.$notify({ group: 'foo', type: 'error', title: 'Attention', text: response.data.message })
          this.deleteAccountFormErrors = { ...this.deleteAccountFormErrors, ...response.data.errors };
        })
        .finally(() => { this.isSendingDeleteAccountForm = false; });
    },
    onGenderChange(gender) { this.personalInfoForm.gender = gender; },
  },
}
</script>

<style>
.profile-informations-form .v-text-field .v-label {
  top: 1rem !important;
}

.profile-informations-form .v-text-field .v-label--active {
  transform: translateY(-40px) scale(1) !important;
}

.profile-informations-form input[type="text"], 
.profile-informations-form input[type="password"] {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  height: 3rem !important;
  box-shadow: none !important;
}

.profile-informations-form input[type="text"]:disabled, 
.profile-informations-form input[type="password"]:disabled {
  background-color: #f5f5f5 !important;
  border: none !important;
  box-shadow: none !important;
}

.profile-informations-form .v-text-field .v-messages {
  color: #f44336 !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
}
</style>