<template>
  <div id="register-activity-question-container">
    <div class="tw-flex tw-flex-row tw-items-center dynamic-bg tw-text-white tw-p-2 tw-mb-6">
      <span>{{ activity }}</span>
    </div>
    <RegisterActivityItem
        @handleSelectItem="handleSelectRegisterActivityItem"
        @handlePersistResponse="handlePersistResponse"
        v-for="item in registerModules"
        :key="item.id"
        :register="item"
        :is-preview="false"
        :activity="activity"
        :user="user"
    />
    <p class="tw-w-10/12 tw-my-4 tw-mx-auto register-activity-footer-text ">
      Les informations que vous avez renseignées seront versées dans le registre de traitement de l’entreprise.
      Vous sélectionnez vos rubriques dans l’ordre que vous souhaitez et pouvez revenir compléter jusqu’à validation.
    </p>
    <div class="tw-text-center">
      <PrimaryButtonComponent
          @click="submit"
          :loading="isSubmitLoading"
          class="register-activity-footer-button"
          :disabled="ValidateDisabled"
      >
        Valider
      </PrimaryButtonComponent>
    </div>

  </div>
</template>

<script>

import RegisterActivityItem from "@/features/ProcessingRegister/components/RegisterActivityItem";
import {
  getAllRegisterModulesWithQuestionsByToken
} from "@/features/ProcessingRegister/services/appApi";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
export default {
  name: "RegisterActivity",
  components: {PrimaryButtonComponent, RegisterActivityItem},
  data() {
    return {
      isDataLoading: false,
      isSubmitLoading: false,
      registerModules: []
    }
  },
  props: {
    activity: {
      type: String,
      default: 'activité'
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ValidateDisabled() {
     return !this.registerModules.every( item => item.status === 'valide');
    },
  },
  mounted() {
    this.getAllRegisterModulesUsingToken();
  },
  methods: {
    getAllRegisterModulesUsingToken() {
      this.isDataLoading = true;
      getAllRegisterModulesWithQuestionsByToken(this.$route.params.token)
          .then(response => {
            this.registerModules = response.data.data.map(obj => ({ ...obj, selected: false }))
          })
          .catch(err => console.log(err))
          .finally(() => this.isDataLoading = false)
    },
    handleSelectRegisterActivityItem(args) {
      this.registerModules.map(register => {
        if (register.id === args.id) {
          register.selected = !register.selected;
        }
        else {
          register.selected = false;
        }
      })
    },
    handlePersistResponse(args) {
      this.registerModules.map(register => {
        if (register.id === args.id) {
          register.status = args.status;
          register.registerModuleQuestions = args.questions;
          register.selected = false;
        }
      })
    },
    submit() {
      this.$router.replace({ name: 'Register.Activity.Token.Finish', params: { token: this.$route.params.token } });
    }
  }
}
</script>
<style lang="scss" scoped>
#register-activity-question-container {
  .register-activity-footer-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--cta-bg-color);
  }

  .register-activity-footer-button {
    width: 210px !important;
    padding: 8px 32px !important;
  }
}
</style>
