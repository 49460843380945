<template>
  <div id="visa-paiement-container">
    <div class="tw-flex tw-flex-row">
      <div class="sm:tw-w-3/12">
        <SignupSecondaryTextComponent class="public-text-register bleu tw-ml-3 dynamic-text">
          <b> Récapitulatif </b>
        </SignupSecondaryTextComponent>
      </div>
      <div class="tw-w-7/12 tw-ml-6">
        <SignupSecondaryTextComponent class="public-text-register bleu tw-ml-3 dynamic-text">
          <b> Ajouter un compte courant </b>
        </SignupSecondaryTextComponent>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-flex-row visa-paiement-sub-container">
      <div class="md:tw-w-3/12 lg:tw-w-3/12 paiement-border-div">
        <SignupSecondaryTextComponent class="public-text-register">
          Date : {{ currentDate }} UTM<br />
          Solution {{user.plan.salaryRange === '0 - 0' ? `Module sous-traitance` : `${user.plan.salaryRange} salariés` }}<br />
          Abonnement {{ user.type === 'monthly' ? 'mensuel' : 'annuel' }}<br />
          <b>Montant : {{ user.price }} € HT / {{ ((user.price) * 1.2).toFixed(2) }} € TTC</b>
        </SignupSecondaryTextComponent>
      </div>
      <div class="md:tw-w-9/12 lg:tw-w-9/12 tw-flex tw-flex-col tw-space-y-4 tw-px-3">
        <StripeElementPayment
          ref="paymentRef"
          :pk="publicKey"
          :elements-options="elementsOptions"
          :confirm-params="confirmParams"
          v-if="clientSecret.includes('pi_')"
          @error="(isLoading = false, buttonText = 'validez votre carte de paiement')"
        />
        <div v-else class="tw-mt-2 tw-p-4" id="card-element"></div>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-flex-row tw-justify-end">
      <button class="validate-button" @click="submit" :disabled="isLoading">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import { attachPaymentMethod } from "@/features/company/services/appApi";
import { loadStripe } from '@stripe/stripe-js';
import moment from "moment";

export default {
  name: "VisaPaiementForm",
  components: {SignupSecondaryTextComponent, StripeElementPayment},
  props: {
    user: {},
    selectedType: {
      type: String,
      default: ''
    },
    clientSecret: {
      type: String,
      default: ''
    },
    publicKey: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isLoading: false,
      buttonText: 'validez votre carte de paiement',
      elementsOptions: {
        appearance: {
          theme: "stripe",
        },
      },
      confirmParams: {
        return_url: ``,
      },
      stripe: null,
      cardElement: null,
    }
  },
  computed: {
    currentDate() {
      return moment().format('DD/MM/YY - HH:MM')
    },
    referer() {
      return this.$store.state.referrer;
    },
    userState() {
      return this.$store.state.user;
    },
    dashboardName() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.userState.expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    }
  },
  async mounted() {
    if (!this.clientSecret.includes('pi_')) {
      this.stripe = await loadStripe(this.publicKey);
      const elements = this.stripe.elements();
      const style = {
        base: {
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#32325d',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a',
          },
        },
      };
      this.cardElement = elements.create('card', { style });
      setTimeout(() => { 
        this.cardElement.mount('#card-element');
      }, 1000);
    }

    this.elementsOptions.clientSecret = this.clientSecret;
    this.confirmParams.return_url = window.location.origin + this.$router.resolve({ name: this.dashboardName }).href;
  },
  watch: {
    clientSecret: {
      handler: function (_) {
        this.elementsOptions.clientSecret = _;
      },
      deep: true,
    },
  },
  methods: {
    async submit() {
      this.isLoading = true;
      this.buttonText = 'Chargement...';

      if (!this.clientSecret.includes('pi_')) {
        const { setupIntent: { payment_method }, error } = await this.stripe.confirmCardSetup(this.clientSecret, {
          payment_method: {
            card: this.cardElement,
          },
        });

        if (payment_method) {
          try {
            await attachPaymentMethod({ paymentMethodId: payment_method });
            this.$snackbar.showAlertMessage({ 
              message: 'Le paiement a été effectué avec succès', 
              type: "success" 
            });
            this.$router.push({ name: this.dashboardName });
          }
          catch(error) { console.log(error); }
        }
        if (error) this.buttonText = error.message;
        this.isLoading = false;
        return;
      }
      
      this.$refs.paymentRef.submit();
    }
  }
}
</script>
