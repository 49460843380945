import ApiService from "@/services/api.service";
import axios from "axios";
import { serverConfig } from "@/config/serverConfig";
// import { serverConfig } from "../../../config/serverConfig";

export const firstStepSignUpApi = (data) => {
  return ApiService.post(`auth/signup/first-step`, {
    firstName: data.firstName,
    gender: data.gender,
    lastName: data.lastName,
    email: data.email,
    socialReason: data.socialReason,
    sirenOrRna: data.sirenOrRna,
    phone: data.phone,
    password: data.password,
  });
};


export const removeImage = (uri) => {
  return axios.post(`${serverConfig.appUrl}/profile/remove-image`,uri);
};
export const saveExcel = (url) => {
  return axios.get(url, {responseType: 'blob'});
};
export const generateExcel = () => {
  return axios.post(`${serverConfig.appUrl}/collaborator/register/export`);
};
export const lastStepSignUpApi = (token) => {
  return axios.post(`${serverConfig.appUrl}/auth/signup/last-step/${token}`);
};
export const generatePDF = (data) => {
  return ApiService.post(`reference/generate-pdf`,data);
};
export const getReferenceByDocument = (documentId, page = 1, limit = 10) => {
  return ApiService.get(`reference/get-references?documentId=${documentId}&page=${page}&limti=${limit}`);
};
export const VerifyReferenceEmailReception = (token) => {
  return ApiService.get(`reference/${token}`);
};
export const getReferenceCountByDocument = (documentId) => {
  return ApiService.get(`reference/get-references-count?documentId=${documentId}`);
};
export const getSubcontractorsList = () => {
  return ApiService.get(`subcontractor/list/subconstactors`);
};

export const getSendingReferences = () => {
  return ApiService.get(`reference/get-sending-references`);
};
export const getDpo = () => {
  return ApiService.get(`profile/get-dpo`);
};
//Dashboard
export const sendContactForm = (data) => {
  return ApiService.post(`contact`, data);
};

export const getDashboardData = () => {
  return ApiService.get(`dashboard`);
};

export const getUserDataByToken = () => {
  return ApiService.get(`profile`);
};
// mon entreprise update info
export const updateCompany = (data) => {
  return ApiService.put(`profile/company`, data);
};

// mon entreprise parametteres
export const updateUserApi = (data) => {
  return ApiService.put(`profile/user`, data);
};

export const updateUserEmail = (data) => {
  return ApiService.post(`auth/security/change-email`, data);
};

export const updateUserPassword = (data) => {
  return ApiService.post(`auth/security/change-password`, data);
};

export const deleteAccountUser = (data) => {
  return ApiService.post(`auth/security/delete-account`, data);
};

export const getRelatedAdmins = (query) => {
  return ApiService.get(`profile/related-admins?page=${query.page}&perPage=${query.limit}`);
};

export const getUserInvoices = (query) => {
  return ApiService.get(`profile/invoices?page=${query.page}&perPage=${query.limit}`);
};

export const getUserPaymentMethods = () => {
  return ApiService.get(`profile/payment-methods`);
};

export const removeUserPaymentMethod = (paymentMethodId) => {
  return ApiService.post(`profile/remove-payment-method`, {paymentMethodId});
};

export const setUserDefaultPaymentMethod = (paymentMethodId) => {
  return ApiService.post(`profile/set-default-payment-method`, {paymentMethodId});
};

export const getUserSignInLogs = (query) => {
  return ApiService.get(`profile/signin-history?page=${query.page}&perPage=${query.limit}`);
};

export const updateAdmin = (data) => {
  return ApiService.put(`profile/update-admin`, data);
};

export const getStripePublicKey = () => {
  return ApiService.get(`profile/stripe-public-key`);
};

export const attachPaymentMethod = (data) => {
  return ApiService.post(`profile/attach-payment-method`, data);
};

export const getUserUpcomingSubscription = () => {
  return ApiService.get(`profile/upcoming-subscription`);
};

export const upgradeUserSubscription = (data) => {
  return ApiService.post(`profile/upgrade-subscription`, data);
};

export const deleteAdmin = (data) => {
  return ApiService.post(`profile/delete-admin`, data);
};

export const getNotificationList = () => {
  return ApiService.get(`notification/params`);
};

export const changeNotificationStatus = (data) => {
  return ApiService.put(`notification/params`, data);
};

// mon entreprise partage
export const ShareValiderContactApi = (data) => {
  return ApiService.post(`quiz/respond-invitations`, data);
};
export const HistoriqueValiderContactApi = (data) => {
  return ApiService.post(`quiz/respond-invitations`, data);
};
export const HistoriqueDeleteContactApi = (id) => {
  return ApiService.delete(`quiz/cancel/${id}`);
};

export const ShareGetContactApi = () => {
  return ApiService.get(`quiz/new-invitations`);
};

export const HistoriqueShareGetContactApi = () => {
  return ApiService.get(`quiz/invitations`);
};

// mon entreprise collaborateur
export const getCollaborator = (page = 1) => {
  return ApiService.get(`collaborator?page=${page}`);
};
export const collaboratorCount = (type) => {
  return ApiService.get(`collaborator/${type}/count`);
};
export const deleteCollaborator = (id) => {
  return ApiService.delete(`collaborator/${id}`);
};
export const inviteCollaborator = (id) => {
  return ApiService.get(`collaborator/${id}/invite`);
};
export const inviteCollaboratorElearning = (id) => {
  return ApiService.post(`collaborator/send-invitation-quiz/${id}`);
};
export const storeCollaborator = (data) => {
  return ApiService.post(`collaborator`, data);
};
export const resendCollaborator = (type, id) => {
  return ApiService.post(`collaborator/repost-one/${type}`, {moduleId: id});
};
export const storeOneCollaborator = (type, data) => {
  return ApiService.post(`collaborator/post-one/${type}`, data);
};

export const storeMultiCollaborator = (type, data) => {
  return ApiService.post(`collaborator/invite-many-collaborator/${type}`, data);
};

export const getNewCollaborators = (type, ids) => {
  return ApiService.get(`collaborator/new/${type}/${ids}`);
};

export const getNewCollaboratorsPaginate = (type, page = 1, key = "",perPage=10, filter, orderBy, orderType) => {
  return ApiService.get(`collaborator/new/${type}?page=${page}&keyWord=${key}&perPage=${perPage}&gouvernance=${filter.gouvernance}&ecosysteme=${filter.ecosysteme}&elearning=${filter.elearning}&orderBy=${orderBy}&orderType=${orderType}`);
};
export const getNewCollaboratorswithoutPagination = (type) => {
  return ApiService.get(`collaborator/old/${type}`);
};

export const getNewCollaboratorByToken = (type, token) => {
  return ApiService.get(`collaborator/get-new-by-token/${type}/${token}`);
};

export const changeNewCollaboratorStatus = (type, id, status) => {
  return ApiService.get(`collaborator/magic-email/${type}/${id}/${status}`);
};
export const storeCollaboratorAnswers = (token, data) => {
  return ApiService.post(
    `collaborator/post-ecosystem-quiz-step-2/${token}`,
    data
  );
};
export const storeQuestionnaireCollaborator = (token, data) => {
  return ApiService.post(
    `collaborator/post-ecosystem-quiz-step-1/${token}`,
    data
  );
};
export const storeCollaboratorElearningAnswers = (token, data) => {
  return ApiService.post(`collaborator/post-elearning-quiz/${token}`, data);
};
export const getCollaboratorAnswers = (id) => {
  return ApiService.get(`collaborator/get-new-by-id/${id}`);
};
export const updateCollaboratorAnswers = (id, data) => {
  return ApiService.post(`collaborator/update-new-by-id/${id}`, data);
};

export const importCollaborators = (data) => {
  return ApiService.post(`collaborator/upload`, data);
};
export const uploadModelCollaborators = (data) => {
  // url: `${this.$app_url}/api/v1/agency/commercial_campaign/file/panorama`,
  // method: "GET",
  // responseType: "blob",
  return ApiService.post(`collaborator`, data);
};

// questionnaire
export const getQuiBlueprint = () => {
  return ApiService.get(`quiz/blueprint`);
};
export const getPublicQuiBlueprint = () => {
  return ApiService.get(`quiz/public-blueprint`);
};
export const getQuiz = () => {
  return ApiService.get(`quiz`);
};
export const getQuizDetail = () => {
  return ApiService.get(`quiz/detail`);
};
export const storeQuiz = (data) => {
  return ApiService.post(`quiz`, data);
};
export const storePublicQuiz = (data) => {
  return ApiService.post(`quiz/public`, data);
}

export const storeDraftQuiz = (data) => {
  return ApiService.post(`quiz/draft`, data);
};

// Company notifications
export const getNotificationUser = () => {
  return ApiService.get(`notification`);
};

export const deleteNotificationUser = (id) => {
  return ApiService.delete(`notification/${id}`);
};

// Company notifications
export const readNotificationUser = (id) => {
  return ApiService.get(`notification/${id}`);
};

export const getCnilRssFlux = () => {
  return ApiService.get(`statistics/cnil`);
};

export const storeProcessFile = (data) => {
  return ApiService.post(`proccess/file-upload`, data);
};

export const getProcessByCompanyId = (companyId) => {
  return ApiService.get(`proccess/get-by-company/${companyId}`);
};

export const getCollaboratorDashboardDetail = (type) => {
  return ApiService.get(`dashboard/collaborator/${type}/detail`);
};

//EXPERT

export const getDataBaseClientExpert = (page = 1, filters) => {
  return ApiService.get(
    `expert-client/paginate` +
    `?page=${page}&` +
    `key_word=${filters.keyWord ?? ""}&` +
    `orderBy=${filters.orderBy ?? ""}&` +
    `orderType=${filters.orderType ?? ""}&` +
    `expert_collaborator_filter=${filters.collaboratorFilter ?? ""}&` +
    `expert_team_filter=${filters.teamFilter ?? ""}&` +
    (filters.noLimit ? `&noLimit=true` : "")
  );
};

export const validateExpertClient = (data) => {
  return ApiService.post(`expert-client/validate-one`, data);
}

export const createExpertClient = (data) => {
  return ApiService.post(`expert-client/create-one`, data);
}

export const deleteClient = (id) => {
  return ApiService.delete(`expert-client/${id}`);
};

export const loginToClientExpert = (id) => {
  return ApiService.post(`expert-client/login-client/${id}`);
};

export const updateClient = (data) => {
  return ApiService.post(`expert-client/update-one`, data);
};

export const validateExpertTeamMember = (data) => {
  return ApiService.post(`expert-client/validate-team-member`, data);
}

export const validateExpertMultiTeamMember = (data) => {
  return ApiService.post(`expert-client/validate-multi-team-member`, data);
}

export const inviteExpertTeamMembers = (data) => {
  return ApiService.post(`expert-client/invite-team-members`, data);
}

export const resendInvitationForTeamMember = (memberId) => {
  return ApiService.post(`expert-client/resend-invitation-for-team-member`, { memberId });
}

export const getExpertTeamMembers = (page = 1, orderBy, orderType) => {
  return ApiService.get(`expert-client/team-members?page=${page}&orderBy=${orderBy}&orderType=${orderType}`);
}

export const removeExpertTeamMember = (id) => {
  return ApiService.post(`expert-client/remove-team-member/`, { memberId: id });
}

export const updateExpertTeamMember = (data) => {
  return ApiService.post(`expert-client/update-team-member/`, data);
}

export const getTeamMembersWithoutPagination = () => {
  return ApiService.get(`expert-client/team-members-without-pagination`);
}

export const syncCollaboratorsToCompany = (data) => {
  return ApiService.post(`expert-client/sync-collaborators-to-company`, data);
}

export const syncTeamsToCompany = (data) => {
  return ApiService.post(`expert-client/sync-teams-to-company`, data);
}

export const getTeams = (page = 1, filters) => {
  return ApiService.get(
    `expert-client/teams` +
    `?page=${page}&` +
    `orderBy=${filters.orderBy ?? ""}&` +
    `orderType=${filters.orderType ?? ""}`
  );
};

export const setLeadForTeamMember = (data) => {
  return ApiService.post(`expert-client/teams/set-lead`, data);
}

export const createTeam = (data) => {
  return ApiService.post(`expert-client/teams/create`, data);
}

export const updateTeam = (id, data) => {
  return ApiService.post(`expert-client/teams/update/${id}`, data);
}

export const deleteTeam = (id) => {
  return ApiService.delete(`expert-client/teams/delete/${id}`);
}

export const deleteTeamMember = (teamId, memberId) => {
  return ApiService.delete(`expert-client/teams/${teamId}/delete-member/${memberId}`)
}

export const getTeamsWithoutPagination = () => {
  return ApiService.get(`expert-client/teams/without-pagination`);
}

export const getAnalyticsForClient = (companyId) => {
  return ApiService.get(`expert-client/get-analytics-for-client/${companyId}`);
}

export const getAllCompanyStructures = () => {
  return ApiService.get(`profile/company-structures`);
}

export const getStripePaymentIntent = (method) => {
  return ApiService.get(`expert-client/get-stripe-payment-intent/${method}`);
}