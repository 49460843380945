<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-10 tw-min-h-screen tw-relative">
      <v-overlay :value="isLoadingData" class="tw-z-99">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <TitleComponent class="public-title-auth tw-mt-8" :text="pageTitle" />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-8">
        Renseignez les informations concernant la personne habilitée à répondre
        au questionnaire d’évaluation de votre conformité au RGPD.
      </SignupSecondaryTextComponent>
      <v-form
        @submit="onSubmit"
        v-model="formValid"
        class="tw-pb-16 tw-px-16 tw-m-auto"
        autocomplete="off"
      >
        <h3 class="tw-font-bold tw-text-base tw-my-8">
          Veuillez renseigner les informations de la personne habilitée à
          répondre
        </h3>

        <v-row>
          <v-col cols="12" md="4" lg="4">
            <RadioButtonComponent
              :rules="rules.gender"
              :items="genders"
              @change="onGenderChange"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4">
            <TextFieldComponent
              :rules="rules.firstName"
              :error-messages="errors.firstName"
              v-model="form.firstName"
              label="Prénom"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4">
            <TextFieldComponent
              :rules="rules.lastName"
              v-model="form.lastName"
              :error-messages="errors.lastName"
              label="Nom"
            />
          </v-col>
          <v-col cols="12" md="8" lg="8">
            <TextFieldComponent
              :rules="rules.role"
              v-model="form.role"
              label="Function"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="9" lg="8">
            <TextFieldComponent
              :rules="rules.email"
              v-model="form.email"
              label="Email"
            />
          </v-col>
          <v-col cols="12" md="3" lg="4">
            <TextFieldComponent
              :rules="rules.phone"
              v-model="form.phone"
              label="Téléphone"
            />
          </v-col>
        </v-row>

        <div class="tw-flex tw-mx-6 tw-justify-end tw-mt-14">
          <PrimaryButtonComponent
            :loading="isSendingForm"
            :disabled="!formValid || isSendingForm"
            type="submit"
            >Terminer
            <v-icon right dark>
              mdi-arrow-right
            </v-icon></PrimaryButtonComponent
          >
        </div>
      </v-form>
      <mention-legal></mention-legal>
    </div>
  </v-container>
</template>

<script>
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import RadioButtonComponent from "@/components/common/RadioButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import TitleComponent from "@/components/common/TitleComponent";
import MentionLegal from "@/components/common/MentionLegal";
import { validationRules } from "@/utils/validation-rules";
import {
  getDataFromToken,
  AssistSendInvitationToInterlocuteurSubcontractorApi,
} from "../../services/appApi";
import _ from "lodash";

export default {
  components: {
    SignupSecondaryTextComponent,
    PrimaryButtonComponent,
    RadioButtonComponent,
    TextFieldComponent,
    TitleComponent,
    MentionLegal,
  },
  data() {
    return {
      formValid: false,
      isLoadingData: true,
      isSendingForm: false,
      company: null,
      errors: {
        gender: "",
        firstName: "",
        lastName: "",
        role: "",
        email: "",
        phone: "",
      },
      form: {
        gender: "",
        firstName: "",
        lastName: "",
        role: "",
        email: "",
        phone: "",
      },
      genders: [
        {
          value: "male",
          label: "Monsieur",
        },
        {
          value: "female",
          label: "Madame",
        },
      ],
      rules: {
        gender: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "gender"),
        ],
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "lastName"),
        ],
        role: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "role"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
      },
    };
  },
  computed: {
    pageTitle() {
      return this.company
        ? `Vous déclarez ne pas être le bon interlocuteur de ${this.company.tradeName} concerné par le questionnaire d’évaluation de votre conformité au RGPD.`
        : "";
    },
  },
  methods: {
    onGenderChange(value) {
      this.form.gender = value;
    },
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      AssistSendInvitationToInterlocuteurSubcontractorApi(
        this.$route.params.token,
        this.$route.params.id,
        this.form
      )
        .then((response) => {
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "success",
          });
          this.$router.push({
            name: "landing-public",
          });
        })
        .catch(({ response }) => {
          this.isSendingForm = false;
          if (_.isArray(response.data.message)) {
            response.data.message.forEach((item) => {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                text: item,
              });
            });
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Attention",
              text: response.data.message,
            });
          }
          if (response.status === 400) {
            this.errors = { ...this.errors, ...response.data };
          }
          this.$refs.form.validate();
        });
    },

    fetchData() {
      getDataFromToken(this.$route.params.token)
        .then(({ data: { data } }) => {
          this.isLoadingData = false;
          this.company = data.workInCompany;
        })
        .catch(({ response }) => {
          this.isLoadingData = false;
          if (response.status === 404) {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
            this.$router.replace({ name: "TokenExpired" });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: quelque chose s'est mal passé",
              type: "error",
            });
          }
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
