<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-10 tw-min-h-screen">
      <StepsComponent :step="1" />
      <div v-if="!formSent" class="tw-relative tw-min-h-screen">
        <TitleComponent class="tw-mt-8" text="Créez votre compte" />
        <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
          Pour commencer à démêler votre mise en conformité, nous avons besoin
          de quelques informations.<br />
          Cela ne vous prendra que 2 minutes !
        </SignupSecondaryTextComponent>
        <v-form
          ref="form"
          @submit="onSubmit"
          v-model="formValid"
          class="tw-mt-8"
          autocomplete="off"
        >
          <RadioButtonComponent
            :rules="rules.gender"
            :items="genders"
            @change="onGenderChange"
          />
          <div class="grid-step1">
            <TextFieldComponent
              :rules="rules.firstName"
              :error-messages="errors.firstName"
              v-model="form.firstName"
              label="Prénom"
            />
            <TextFieldComponent
              :rules="rules.lastName"
              v-model="form.lastName"
              :error-messages="errors.lastName"
              label="Nom"
            />
            <TextFieldComponent
              :rules="rules.email"
              v-model="form.email"
              label="Adresse e-mail"
            />
            <TextFieldComponent
              :rules="rules.socialReason"
              v-model="form.socialReason"
              :error-messages="errors.socialReason"
              label="Raison social de l'entreprise"
            />
            <TextFieldComponent
              :rules="rules.sirenOrRna"
              v-model="form.sirenOrRna"
              :error-messages="errors.sirenOrRna"
              textRules="EX : 894730001"
              label="Numéro SIREN / RNA"
            />
            <select-box-component
            :rules="rules.legalStatus" 
            v-model="form.legalStatus" 
            :items="legalStatuses"
            name="legalStatus"
            label="Structure juridique"
            />
          
            <TextFieldComponent
              :rules="rules.phone"
              v-model="form.phone"
              :error-messages="errors.phone"
              label="Numéro de téléphone"
              textRules="Ce numéro vous servira lors de la double authentification."
              autocomplete="new-password"
            />
            <PasswordInputComponent
              @keyup="handelPassword"
              v-model="form.password"
              :rules="rules.password"
              :error-messages="errors.password"
              label="Mot de passe"
              validate
              passwordRules="Au moins 12 caractères, dont 1 majuscule, 1 chiffre, 1 minuscule, 1 caractère
spécial (., @, !, _, %, #, :, $, *, ..., -)"
            />
            <PasswordInputComponent
              :disabled="disableComfirmPassword"
              :rules="rules.confirmPassword"
              v-model="form.confirmPassword"
              :error-messages="errors.confirmPassword"
              label="Vérification de mot de passe"
            />
          </div>
          <div class="tw-flex tw-justify-center tw-pb-12 md:tw-pb-20 tw-mt-6">
            <PrimaryButtonComponent
              :style="{
                backgroundColor: `${referer.theme.ctaBgColor} !important`,
                color: `${referer.theme.ctaTextColor} !important`,
              }"
              :loading="isSendingForm"
              :disabled="!formValid || isSendingForm"
              type="submit"
              >Créer</PrimaryButtonComponent
            >
          </div>
        </v-form>
        <p class="registration-iml">
          Les informations à caractère personnel recueillies dans le cadre de
          votre demande de souscription à la plateforme Viqtor® sont
          enregistrées dans un fichier informatisé par la société DBWO pour
          permettre la finalisation de l’inscription de votre organisation à nos
          services. Elles seront conservées jusqu’à la validation de la
          souscription, période durant laquelle elles pourront être
          régulièrement mises à jour à votre demande. Hors demande de votre
          part, elles seront détruites en cas de non-finalisation de votre
          demande sous un délai de 2 mois après cet envoi. Ces données sont
          destinées au personnel administratif de DBWO et conservées sur les
          serveurs de l’entreprise OVH qui héberge le site Viqtor® sur le
          territoire français. Conformément à la loi « informatique et libertés
          », vous pouvez exercer votre droit d'accès aux données vous concernant
          et les faire rectifier en contactant : dpo@dbwo.legal.
        </p>
      </div>
      <div v-if="formSent">
        <TitleComponent
          class="public-title-auth tw-mt-8"
          text="Première étape terminée !"
        />
        <SignupSecondaryTextComponent
          class="public-text-register tw-mt-4 tw-leading-7"
        >
          Pour poursuivre la création de votre compte, vous devez valider votre
          adresse email en cliquant sur le lien qui vous a été envoyé.
        </SignupSecondaryTextComponent>
      </div>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "../../../components/common/TitleComponent";
import SignupSecondaryTextComponent from "../components/SignupSecondaryTextComponent";
import RadioButtonComponent from "../../../components/common/RadioButtonComponent";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import PasswordInputComponent from "../../../components/common/PasswordFieldComponent";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import StepsComponent from "../components/StepsComponent";
import { validationRules } from "../../../utils/validation-rules";
import { firstStepSignUpApi } from "../services/appApi";
import SelectBoxComponent from '../../../components/common/SelectBoxComponent.vue';
export default {
  name: "FirstStepRegistrationPage",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  components: {
    StepsComponent,
    PrimaryButtonComponent,
    PasswordInputComponent,
    TextFieldComponent,
    RadioButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
    SelectBoxComponent,
  },
  data() {
    return {
      formSent: false,
      legalStatuses: [
                    { header: "PRIVÉE" },
                    { name: "Entreprise individuelle (EI)" ,label: "Entreprise individuelle (EI)" },
                    { name: "Entreprise unipersonnelle à responsabilité limitée (EURL)" ,label: "Entreprise unipersonnelle à responsabilité limitée (EURL)" },
                    { name: "Société à responsabilité limitée (SARL)" ,label: "Société à responsabilité limitée (SARL)" },
                    { name: "Société anonyme (SA)" ,label: "Société anonyme (SA)" },
                    { name: "Société par actions simplifiée unipersonnelle (SASU)" ,label: "Société par actions simplifiée unipersonnelle (SASU)" },
                    { name: "Société par actions simplifiée (SAS)" ,label: "Société par actions simplifiée (SAS)" },
                    { name: "Société en nom collectif (SNC)" ,label: "Société en nom collectif (SNC)" },
                    { name: "Société en commandite simple (SCS)" ,label: "Société en commandite simple (SCS)" },
                    { name: "Société en commandite par actions (SCA)" ,label: "Société en commandite par actions (SCA)" },
                    { name: "Société coopérative et participative (SCOP)" ,label: "Société coopérative et participative (SCOP)" },
                    { name: "Société Civile Immobilière (SCI)" ,label: "Société Civile Immobilière (SCI)" },
                    { name: "Société d’exercice libéral à responsabilité limitée (SELARL)" ,label: "Société d’exercice libéral à responsabilité limitée (SELARL)" },

                    { header: "PUBLIQUE" },
                    { name: "Établissement public à caractère administratif (EPA)" ,label: "Établissement public à caractère administratif (EPA)" },
                    { name: "Établissement public à caractère industriel et commercial (EPIC)" ,label: "Établissement public à caractère industriel et commercial (EPIC)" },
                    { name: "Société d'économie mixte (SEM)" ,label: "Société d'économie mixte (SEM)" },
                    { name: "Société publique locale (SPL)" ,label: "Société publique locale (SPL)" },
                    { name: "Mairie / Établissement public communal (EPC)" ,label: "Mairie / Établissement public communal (EPC)" },

                    { header: "ASSOCIATIVE" },
                    { name: "Fédération (FED)" ,label: "Fédération (FED)" },
                    { name: "Association loi 1901 (AMU)" ,label: "Association loi 1901 (AMU)" },
                    { name: "Organisation non gouvernementale (ONG)" ,label: "Organisation non gouvernementale (ONG)" },
                    { name: "Association reconnue d'utilité publique (ARUP)" ,label: "Association reconnue d'utilité publique (ARUP)" },
                    { name: "Association d'intérêt général (AIG)" ,label: "Association d'intérêt général (AIG)" },
                    { name: "Association culturelle (ACU)" ,label: "Association culturelle (ACU)" },
                    { name: "Association sportive (ASP)" ,label: "Association sportive (ASP)" },
                    { name: "Association caritative (ACA)" ,label: "Association caritative (ACA)" },

                    { name: "AUTRE",label: "AUTRE" },


                ],
      formValid: false,
      isSendingForm: false,
      disableComfirmPassword: true,
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        socialReason: "",
        sirenOrRna: "",
        legalStatus: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        socialReason: "",
        sirenOrRna: "",
        legalStatus: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "lastName"),
        ],
        gender: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "gender"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        socialReason: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "socialReason"),
        ],
        sirenOrRna: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "sirenOrRna"),
        ],
        legalStatus: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "legalStatus"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
        confirmPassword: [
          validationRules.required,
          (value) => validationRules.passwordMatch(value, this.form.password),
        ],
      },
      genders: [
        {
          value: "male",
          label: "Monsieur",
        },
        {
          value: "female",
          label: "Madame",
        },
      ],
    };
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      firstStepSignUpApi(this.form)
        .then(() => {
          this.isSendingForm = false;
          this.formSent = true;
          this.$snackbar.showAlertMessage({
            message: "Votre compte a été bien créér",
            type: "success",
          });
          window.scrollTo(0, 0);
        })
        .catch(({ response }) => {
          window.scrollTo(0, 0);
          this.isSendingForm = false;
          if (response.status === 400) {
            response.data.message.forEach((err) => {
              if (err === "Company avec le même 'sirenOrRna' existe déjà") {
                this.$router.push({
                  name: "DoubleEmailOrSiren",
                  params: {
                    errorType: "siren",
                  },
                });
              }
              if (err === "User avec le même 'email' existe déjà") {
                this.$router.push({
                  name: "DoubleEmailOrSiren",
                  params: {
                    errorType: "email",
                  },
                });
              }
            });
            response.data.message.forEach((item) => {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                position: "bottom right",
                text: item,
              });
            });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: la requete a échoué",
              type: "error",
            });
          }
          this.$refs.form.validate();
        });
    },
    onGenderChange(value) {
      this.form.gender = value;
    },

    handelPassword(event) {
      if (
        event.target.value.length >= 12 &&
        event.target.value.match(/[a-z]/) &&
        event.target.value.match(/[A-Z]/) &&
        event.target.value.match(/\d+/) &&
        (event.target.value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ||
          event.target.value.includes("-"))
      ) {
        this.disableComfirmPassword = false;
      } else {
        this.form.confirmPassword = "";
        this.disableComfirmPassword = true;
      }
    },
  },
};
</script>

<style scoped></style>
