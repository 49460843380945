<template>
  <div>
    <v-checkbox
      v-model="ids"
      :color="referer.theme.ctaBgColor"
      block
      v-for="item in question"
      :key="item.id"
      :value="item.id"
      :label="item.label"
      readonly
    ></v-checkbox>
  </div>
</template>

<script>
export default {
  name: "CheckButtonQuestionnaireComponent",
  props: {
    question: Object,
    select: Array,
  },
  data () {
    return {
      selectedValue: null,
      selectedId:null,
      selected: [19,20],
    }
  },
  computed: {
    ids() {
      var filter = this.select.map((m) => {
        return m.id;
      });

      return filter;
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods:{
    checkChecked(id){
      return id;
    }
  },
};
</script>

