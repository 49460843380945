<template>
  <div class="tw-flex tw-h-screen" id="company-container">
    <Sidebar v-if="!$route.meta.withoutSidebar" />
    <div
      class="tw-w-12/12 lg:tw-w-9/12 tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden"
    >
      <Header />
      <router-view class="company-content"></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/layout/company/Sidebar.vue";
import Header from "./components/layout/company/Header.vue";
export default {
  name: "Company",
  components: {
    Sidebar,
    Header,
  },
  methods: {
    showMenu() {
      this.$el.querySelector("#sidebar").classList.toggle("tw-hidden");
    },
  }
};
</script>
