<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="tw-px-16 tw-mt-16">
      <v-row class="auth-container tw-mt-20 tw-min-h-screen">
        <v-col cols="12">
          <TitleComponent class="public-title-auth tw-mt-4" text="Entrez sur votre espace." />
          <div class="tw-text-center tw-mt-24">
            <LoginSecondaryTextComponent
                class="public-text-login tw-mt-6 dynamic-text"
            >
               <b>Validation en deux étapes SMS</b>
            </LoginSecondaryTextComponent>
            <LoginSecondaryTextComponent class="public-text-login tw-mt-6" v-if="phone">
              <b>Veuillez entre le code reçu par SMS au numéro</b>
              <br />
              {{ phone.substring(0, 2) }} ** ** **
              {{ phone.substring(phone.length, phone.length - 2) }}.
            </LoginSecondaryTextComponent>
            <LoginSecondaryTextComponent class="public-text-login tw-mt-6">
              <button
                  :disabled="isSendingForm"
                  v-on:click.prevent="resendVerificationCode"
                  :style="{ color: referer.theme.ctaBgColor }"
              >
                Renvoyer le code
              </button>
            </LoginSecondaryTextComponent>
            <v-form
                ref="form"
                @submit="onSubmit"
                v-model="formValid"
                class="tw-mt-10"
            >
              <v-row>
                <v-col cols="4" offset="4">
                  <LoginTextFieldComponent
                      label="Code"
                      class="pa-0 ma-0 tw-h-full"
                      :rules="rules.codeSms"
                      v-model="form.codeSms"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" offset="4">
                  <PrimaryButtonComponent
                      height="40"
                      class="tw-rounded-lg !tw-w-1/3"
                      :loading="isSendingForm"
                      :disabled="!formValid || isSendingForm"
                      type="submit"
                  >Valider
                  </PrimaryButtonComponent>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import LoginSecondaryTextComponent from "@/features/auth/components/LoginSecondaryTextComponent";
import LoginTextFieldComponent from "@/components/common/LoginTextFieldComponent";
import { validationRules } from "@/utils/validation-rules";
import {resend2FaVerificationCode, twoFactorValidation} from "@/features/auth/services/appApi";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "LoginSecondStep",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      formSent: false,
      formValid: false,
      isSendingForm: false,
      errors: {
        codeSms: "",
      },
      form: {
        codeSms: "",
      },
      rules: {
        codeSms: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "codeSms"),
        ],
      },
    };
  },
  props: {
    token: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: '0666666666'
    }
  },
  components: {
    PrimaryButtonComponent,
    LoginTextFieldComponent,
    LoginSecondaryTextComponent,
    TitleComponent,
  },

  methods: {
    resendVerificationCode() {
      resend2FaVerificationCode(this.token)
          .then((response) => {
            this.$router.push({ params: { token: response.data.data.secret } });
            this.isSendingForm = false;
            this.token = response.data.data.secret;
          })
          .catch((err) => {
            this.isSendingForm = false;
            this.$snackbar.showAlertMessage({
              message: err.response.data.message,
              type: "error",
            });
          });
    },
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      const data = {
        token: this.form.codeSms,
        secret: this.token,
      };
      twoFactorValidation(data)
          .then((response) => {
            this.$store.dispatch("setToken", response.data.data.accessToken);
            this.isSendingForm = false;
            this.formSent = true;

            if (!response.data.data.planPriceId || response.data.data.subscribed === false) {
              this.$router.push({ name: "SetupAccountCompany" });
            } else {
              this.$router.push({ name: "Company.Expert.DashboardPage" });
            }
          })
          .catch(({ response }) => {
            this.isSendingForm = false;
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
            if (response.status === 400) {
              this.errors = { ...this.errors, ...response.data };
            }
            this.$refs.form.validate();
          });
    },
  },
};
</script>

<style scoped></style>
