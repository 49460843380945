import { render, staticRenderFns } from "./VerifiedSubContractorPage.vue?vue&type=template&id=54aec327&scoped=true&"
import script from "./VerifiedSubContractorPage.vue?vue&type=script&lang=js&"
export * from "./VerifiedSubContractorPage.vue?vue&type=script&lang=js&"
import style0 from "./VerifiedSubContractorPage.vue?vue&type=style&index=0&id=54aec327&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54aec327",
  null
  
)

export default component.exports