<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-20 tw-min-h-screen">
      <router-link to="/">
        <RetourButtonComponent />
      </router-link>
      <TitleComponent class="public-title-auth tw-mt-4" text="Entrez sur" />
      <TitleComponent class="public-title-auth" text="votre espace." />
      <v-form
        ref="form"
        @submit="onSubmit"
        v-model="formValid"
        class="tw-mt-10"
      >
        <TextFieldComponent
          append-icon="mdi-account-outline"
          class="sm:tw-w-full md:tw-w-1/3"
          :rules="rules.email"
          v-model="form.email"
          label="Adresse e-mail"
          :error-messages="errors.email"
        />
        <PasswordFieldComponent
          class="tw-mt-6 sm:tw-w-full md:tw-w-1/3"
          v-model="form.password"
          :rules="rules.password"
          :error-messages="errors.password"
          label="Mot de passe"
        />
        <v-btn
          tile
          depressed
          @click="onPasswordForget"
          color="white"
          elevation="0"
          class="tw-tracking-normal tw-mt-4 pa-0 tw-normal-case text-transform-none"
        >
          <span class="public-text-login"
                :style="{
            color: `${referer.theme.ctaBgColor}`,
          }"
            >Mot de passe oublié ?</span
          >
        </v-btn>
        <div class="tw-mt-10">
          <PrimaryButtonComponent
              :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor}  !important` }"
            class="tw-rounded-lg"
            :loading="isSendingForm"
            :disabled="!formValid || isSendingForm"
            type="submit"
            >Entrer</PrimaryButtonComponent
          >
        </div>
        <LoginSecondaryTextComponent class="public-text-login tw-mt-16">
          Pas encore de compte ?
          <router-link
            :to="{ name: 'signup' }"
            class="text-decoration-underline"
            :style="{
            color: `${referer.theme.ctaBgColor}`,
          }"
          >
            Inscrivez-vous
          </router-link>
        </LoginSecondaryTextComponent>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import RetourButtonComponent from "@/components/common/RetourButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PasswordFieldComponent from "@/components/common/PasswordFieldComponent";
import LoginSecondaryTextComponent from "@/features/auth/components/LoginSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { validationRules } from "@/utils/validation-rules";
import { login } from "@/features/auth/services/appApi";

export default {
  name: "Login",
  created() {
    // localStorage.clear();
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      formSent: false,
      formValid: false,
      isSendingForm: false,
      errors: {
        email: "",
        password: "",
      },
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
      },
    };
  },
  components: {
    PrimaryButtonComponent,
    LoginSecondaryTextComponent,
    PasswordFieldComponent,
    TextFieldComponent,
    RetourButtonComponent,
    TitleComponent,
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.isSendingForm = true;

      this.$store
        .dispatch("login", this.form)
        .then((response) => {
          this.isSendingForm = false;
          this.formSent = true;
          this.$router.push({
            name: "TwoFactorValidation",
            params: {
              token: response.data.data.secret,
              phone: response.data.data.phone,
            },
          });
        })
        .catch((error) => {
          this.isSendingForm = false;
          
          if (!error.response)
            this.$snackbar.showAlertMessage({ message: "La connexion a échoué", type: "error" });
          else if (error.response.status === 400 || error.response.status === 422)
            this.errors = { 
              email: error.response.data.errors.email 
                ? error.response.data.errors.email[0] : "",
              password: error.response.data.errors.password 
                ? error.response.data.errors.password[0] : "",
            };
          else
            this.$snackbar.showAlertMessage({ message: error.response.data.message, type: "error" });

          this.$refs.form.validate();
        });
    },
    onSubmit1($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      login(this.form)
        .then(() => {
          this.isSendingForm = false;
          this.formSent = true;
          this.$router.push({ name: "TwoFactorValidation" });
        })
        .catch(({ response }) => {
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: "La connexion a échoué",
            type: "error",
          });
          this.$router.push({ name: "TwoFactorValidation" });
          if (response.status === 400) {
            this.errors = { ...this.errors, ...response.data };
          }
          this.$refs.form.validate();
        });
    },
    onPasswordForget() {
      this.$router.push({ name: "reset" });
    },
  },
};
</script>

<style type="scss" scoped></style>
